export default {
    name: "",
    components: {},
    data() {
        return {
            map: {
                center: { lng: 103.960262, lat: 30.690961 },
                zoom: 30,
                show: true,
                dragging: true,
            },
            map2: {
                center: { lng: 104.088057, lat: 30.407416 },
                zoom: 30,
                show: true,
                dragging: true,
            },
            map3: {
                center: { lng: 105.955899, lat: 26.242145 },
                zoom: 30,
                show: true,
                dragging: true,
            },
            map4: {
                center: { lng: 113.255285, lat: 22.67541 },
                zoom: 30,
                show: true,
                dragging: true,
            },


        };
    },
    methods: {

        handler({ BMap, map }) {
            console.log(BMap, map);
            // 鼠标缩放
            map.enableScrollWheelZoom(true);
            // 点击事件获取经纬度
            map.addEventListener("click", function(e) {
                console.log(e.point.lng, e.point.lat);
            });
        },
        handler2({ BMap, map }) {
            console.log(BMap, map);
            // 鼠标缩放
            map.enableScrollWheelZoom(true);
            // 点击事件获取经纬度
            map.addEventListener("click", function(e) {
                console.log(e.point.lng, e.point.lat);
            });
        },
        handler3({ BMap, map }) {
            console.log(BMap, map);
            // 鼠标缩放
            map.enableScrollWheelZoom(true);
            // 点击事件获取经纬度
            map.addEventListener("click", function(e) {
                console.log(e.point.lng, e.point.lat);
            });
        },
        handler4({ BMap, map }) {
            console.log(BMap, map);
            // 鼠标缩放
            map.enableScrollWheelZoom(true);
            // 点击事件获取经纬度
            map.addEventListener("click", function(e) {
                console.log(e.point.lng, e.point.lat);
            });
        },

    },

    computed: {},
    watch: {},
};